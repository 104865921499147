<template>
    <div id="detailsDemonstrationsListTable">
        <b-card>
            <b-row class="mb-2">
                <b-col sm="3" class="text-sm-right">
                    <b>Name:</b>
                </b-col>

                <b-col>{{ demonstration.name }}</b-col>
            </b-row>

            <b-row class="mb-2">
                <b-col sm="3" class="text-sm-right">
                    <b>Owner:</b>
                </b-col>

                <b-col>{{ demonstration.ownerEmail }}</b-col>
            </b-row>

            <b-row class="mb-2">
                <b-col sm="3" class="text-sm-right">
                    <b>Package name:</b>
                </b-col>

                <b-col>{{ demonstration.packageName }}</b-col>
            </b-row>

            <b-row class="mb-2">
                <b-col sm="3" class="text-sm-right">
                    <b>Icon:</b>
                </b-col>

                <b-col>
                    <b-img :src="getIconImageUrl()" width="60"/>
                </b-col>
            </b-row>

            <b-row class="mb-2">
                <b-col sm="3" class="text-sm-right">
                    <b>Short description:</b>
                </b-col>

                <b-col>{{ demonstration.shortDesc }}</b-col>
            </b-row>

            <b-row class="mb-2">
                <b-col sm="3" class="text-sm-right">
                    <b>Long description:</b>
                </b-col>

                <b-col>
                    <p v-if="demonstration.longDesc.length > 100">
                        <nl2br v-if="!longDescVisible" tag="span" :text="getTruncatedLongDesc(demonstration.longDesc)" />
                        <nl2br v-else tag="span" :text="demonstration.longDesc" />
                        <br />
                        <b-button size="sm" @click="longDescVisible = !longDescVisible">
                            <span v-if="!longDescVisible">Show full description</span>
                            <span v-else>Hide full description</span>
                        </b-button>
                    </p>
                    <p v-else>
                        <span>{{demonstration.longDesc}}</span>
                    </p>
                </b-col>
            </b-row>

            <b-row class="mb-2" v-if="isNotNull(demonstration.docPath)">
                <b-col sm="3" class="text-sm-right">
                    <b>Doc Path:</b>
                </b-col>

                <b-col> <a :href="demonstration.docPath">{{ demonstration.docPath }}</a></b-col>
            </b-row>

            <b-row class="mb-2" v-if="isNotNull(demonstration.videoPath)">
                <b-col sm="3" class="text-sm-right">
                    <b>Video Path:</b>
                </b-col>

                <b-col> <a :href="demonstration.videoPath">{{ demonstration.videoPath }}</a></b-col>
            </b-row>

            <b-row class="mb-2">
                <b-col sm="3" class="text-sm-right">
                    <b>Versions (version code):</b>
                </b-col>

                <b-col>
                    <p v-for="(version, index) in demonstration.versions.slice().reverse()" :key="version.id">
                        <span>Published {{formatDate(version.dateInsert)}}</span><b-badge pill variant="secondary" v-if="index == 0" class="mx-2">Latest</b-badge>
                        <br />
                        <span>Version code : {{version.versionCode}}</span>
                        <br />
                        <span>Version name : {{version.versionName}}</span>
                        <br />
                        <b-button size="sm" variant="outline-info" @click="showChangelogModal(version)" class="mr-2">Changelog</b-button>
                        <b-button size="sm" variant="info" @click="download(version.apkName)">Download</b-button>

                    </p>
                    <ChangelogModal :show="changelogModal.showModal" :demo="demonstration" :version="changelogModal.version" :latest="changelogModal.isLatest" @closeChangelogModal="closeChangelogModal" :key="changelogModal.key"></ChangelogModal>
                </b-col>
            </b-row>

            <router-link :to="{ name: 'edit', params: { complexId: demonstration.complexId }}">
                <b-button size="sm" variant="warning"  class="d-block mx-auto">Edit</b-button>
            </router-link>

        </b-card>
    </div>
</template>

<script>
	import moment from 'moment';
	import DemonstrationsApi from "@/assets/js/DemonstrationsApi";
	import ChangelogModal from "./ChangelogModal";
	export default {
		name: "DemonstrationsListTableDetails",
		components: {ChangelogModal},
		props: {
			demonstration: Object
        },
        data(){
			return{
				changelogModal: {
					version: null,
					showModal: false,
					key: 0,
                    isLatest: false
                },
				longDescVisible: false
            }
        },
		methods: {
			getIconImageUrl(){
				return DemonstrationsApi.getIconImageUrl(this.$props.demonstration.complexId);
			},
			download(apkName){
				var url = DemonstrationsApi.getDownloadUrl(apkName);
				window.open(url);
            },
			showChangelogModal(version){
				this.changelogModal.version = version;
				this.changelogModal.showModal = true;
				this.changelogModal.key++;
				if(version.id == this.getLastUploadedVersion().id){
					this.changelogModal.isLatest = true;
                }
            },
			closeChangelogModal(){
				this.changelogModal.showModal = false
				this.$emit('needUpdateDemos');
			},
			getLastUploadedVersion(){
				if(this.isNotNull(this.demonstration) && this.isNotNull(this.demonstration.versions)){
					let lastVersion = this.demonstration.versions[0];
					this.demonstration.versions.forEach(version => {
						if(moment(version.dateInsert, "DD/MM/YYYY HH:mm").isAfter(moment(lastVersion.dateInsert, "DD/MM/YYYY HH:mm"))){
							lastVersion = version
						}
					}, this);
                    return lastVersion
				}
			},
            getTruncatedLongDesc(longDesc){
				return this.$options.filters.truncate(longDesc, 100, "...");
            }
		}
	}
</script>

<style scoped>

</style>