<template>
    <div>
        <h4>Demonstrations List</h4>

        <br/>

        <b-form-input
                v-model="filter"
                placeholder="Type to search"
                type="search"
                reset-button
        ></b-form-input>

        <br/>

        <DemonstrationsListTable
                :currentPage="currentPage"
                :demonstrations="demonstrations"
                :perPage="perPage"
                :filter="filter"
                :key="refreshTable"
                @needUpdateDemos="needUpdateDemos"
        />

        <b-pagination
                v-model="currentPage"
                :per-page="perPage"
                :total-rows="totalRows"
                size="sm"
                class="my-0"
                align="center"
        ></b-pagination>
    </div>
</template>

<script>
	import DemonstrationsApi from "@/assets/js/DemonstrationsApi";
	import DemonstrationsListTable from "../components/DemonstrationsListTable";

	export default {
		name: "DemonstrationsList",
		components: {DemonstrationsListTable},
		data() {
			return {
				demonstrations: [],
				currentDemonstration: null,
				currentPage: 1,
				perPage: 10,
				totalRows: 1,
				filter: null,
				refreshTable: 0
			};
		},
		created() {
			this.retrieveDemonstrations();
		},
		methods: {
			retrieveDemonstrations() {
				DemonstrationsApi.getAll()
					.then(response => {
						this.demonstrations = response.data;
						if(this.isNotNull(response.data)){
							let queryArray = [];
							if(this.isNotNull(this.$route.query) && this.isNotNull(this.$route.query.ids)){
								queryArray = this.$route.query.ids.split(",")
							}
							response.data.forEach(demo => Object.assign(demo, {_showDetails: queryArray.indexOf(demo.id.toString()) !== -1}))
                        }
						this.totalRows = response.data.length;
						this.refreshTable++;
					})
					.catch(e => {
						console.log(e);
					});
			},
			needUpdateDemos(){
				this.retrieveDemonstrations();
            }
		}
	}
</script>

<style scoped>

</style>