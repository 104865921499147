<template>
    <div id="tableDemonstrationsList" v-if="isNotNull(demonstrations)">
        <b-table
                :current-page="currentPage"
                :items="items"
                :fields="fields"
                :per-page="perPage"
                :filter="filter"
                @filtered="onFiltered"
                head-variant="dark"
                bordered
                striped
                hover
        >
            <template #cell(icon)="data">
                <b-img :src="getIconImageUrl(data.item)" width="45"/>
            </template>

            <template #cell(tags)="data">
                <b-badge
                        v-for="tag in data.item.tags"
                        :key="tag.id"
                        pill
                        variant="secondary"
                        class="font-weight-normal mx-1"
                >
                    {{tag.name}}
                </b-badge>
            </template>

            <template #cell(show_details)="row">
                <b-button
                        size="sm"
                        @click="showDetails(row)"
                        class="mr-2"
                >
                    {{ row.detailsShowing ? 'Hide' : 'Show'}}
                </b-button>
            </template>

            <template #row-details="row">
                <DemonstrationsListTableDetails :demonstration="row.item" @needUpdateDemos="needUpdateDemos" />
            </template>
        </b-table>

    </div>
</template>

<script>
	import DemonstrationsApi from "@/assets/js/DemonstrationsApi";
	import DemonstrationsListTableDetails from "./DemonstrationsListTableDetails";
	export default {
		name: "DemonstrationsListTable",
		components: {DemonstrationsListTableDetails},
		props: {
			currentPage: Number,
			demonstrations: Array,
			perPage: Number,
			filter: String
		},
		data() {
			return {
				fields: [
					{key: "icon", label: "", class: 'text-center align-middle'},
					{key: "name", label: "Application", sortable: true, class: 'text-center align-middle'},
					{key: "tags", class: 'text-center align-middle'},
					{key: "shortDesc", label: "Description", class: 'text-center align-middle'},
					{key: "show_details", label: "Details", class: 'text-center align-middle'},
				],
				items: [],
			}
		},
		created(){
			this.items = JSON.parse(JSON.stringify(this.demonstrations));
		},
		methods: {
			//onFiltered(filteredItems) {
			onFiltered() {
				//this.totalRows = filteredItems.length;
				//this.currentPage = 1;
			},
			getIconImageUrl(demonstration){
				return DemonstrationsApi.getIconImageUrl(demonstration.complexId);
			},
			showDetails(row){
				if(row.detailsShowing){
					this.$router.push({name: "demonstrations"})
				}else{
					let queryArray = [];
					if(this.isNotNull(this.$route.query) && this.isNotNull(this.$route.query.ids)){
						queryArray = this.$route.query.ids.split(",")
					}
					queryArray.push(row.item.id);
					this.$router.push({name: "demonstrations", query: {ids: queryArray.join(",")}})
				}
				row.toggleDetails();
			},
			needUpdateDemos(){
				this.$emit('needUpdateDemos');
			}
		}
	}
</script>

<style scoped>

</style>